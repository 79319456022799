export const subsidizeTeamMemberUsage = {
  order: 3,
  name: "Pay for team member usage",
  keywords: "payment method credit card",
  subcategory: "Manage teams",
  markdown: `# Pay for team member usage
  {% tablessContainer paddingStyles="px-5" %}
  **Who can use this feature?**

  {% inlineAppIcon iconName="userGroup" /%}Team {% inlineRouterLink articleId="types-of-roles-in-tower-hunt" %}**primary owners**{% /inlineRouterLink %} and {% inlineRouterLink articleId="types-of-roles-in-tower-hunt" %}**billing members**{% /inlineRouterLink %}.
  {% /tablessContainer %}

  In Tower Hunt, teams can pay for the datapoint usage of their members. This makes it easy to quickly onboard team members without needing to worry about individual billing. Read on to learn how.

  {% callout type="caution" %}
  **Important:** First, make sure you have an active {% inlineRouterLink articleId="add-a-payment-method" %}payment method{% /inlineRouterLink %}.
  {% /callout %}

  ## Connect a payment method to the team

  To pay for team member usage, first you need to connect a payment method to the team:

  {% tablessContainer %}
1. Open the {% inlineAppIcon iconName="userGroup" %}**My Teams**{% /inlineAppIcon %} section of the **Contacts** tab in the {% inlineRouterLink articleId="sidebar" %}sidebar{% /inlineRouterLink %}.
2. Find or {% inlineRouterLink articleId="create-a-team" %}create a team{% /inlineRouterLink %}.
3. Click the {% inlineAppIcon iconName="verticalDots" /%}**team menu button** to the right of the team name and choose **Manage**.
4. Find your name in the list of team members.
5. Click the {% inlineAppIcon iconName="verticalDots" /%}**member menu button** to the right of your name and choose **Connect payment method**.
6. Select the desired payment method from the list. It now appears below your name.
![Team payment method screenshot](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
{% /tablessContainer %}

## Select a primary payment method

When multiple payment methods are connected to a team, one becomes the *primary* account that can be used to pay for team member usage.

{% tablessContainer %}
1. Open the {% inlineAppIcon iconName="userGroup" %}**My Teams**{% /inlineAppIcon %} section of the **Contacts** tab in the {% inlineRouterLink articleId="sidebar" %}sidebar{% /inlineRouterLink %}.
2. Click the {% inlineAppIcon iconName="verticalDots" /%}**team menu button** to the right of the desired team name and choose **Manage**.
4. Find the desired payment method under a team member's name.
5. Click the {% inlineAppIcon iconName="star" /%}**Star button** to set the payment method as the primary payment method for the team.
![Primary team payment method screenshot](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)
{% /tablessContainer %}

  ## Pay for team member usage

  Once a payment method is connected to the team, it is easy to authorize team billing:

  {% tablessContainer %}
1. Open the {% inlineAppIcon iconName="userGroup" %}**My Teams**{% /inlineAppIcon %} section of the **Contacts** tab in the {% inlineRouterLink articleId="sidebar" %}sidebar{% /inlineRouterLink %}.
2. Click the {% inlineAppIcon iconName="verticalDots" /%}**team menu button** to the right of the desired team name and choose **Manage**.
3. Click the **Pay for member usage** toggle switch.
4. Click **Authorize and save**.
{% /tablessContainer %}`,
};
